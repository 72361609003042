import {headerEvents} from "./modules/headerEvents";
import {tableHeaderFixed} from "./modules/tableHeaderFixed";
import {datePicker, dateRangePicker} from "./helper/datetimepicker";
import Swal from 'sweetalert2';

let $ = jQuery;
$.datetimepicker.setLocale('ja');

//common
$(function () {
    headerEvents();
    tableHeaderFixed();
    if($('.datepicker').length){
        datePicker('.datepicker');
    }
    if($('.date-range-picker').length){
        dateRangePicker('.date-range-picker');
    }

    $(window).ready(function (){
        //highlight result search and scroll to row search
        if($('[name=button_type]').val() !== 'filter'){
            let line = location.hash.replace('#',''),
                $line = $('.list-page__table').eq(0).find('[data-id="' + line + '"]');
            if($line.length && line){
                var $titleSearch = $('select[name="select_title"]').val();
                var $keySearch = $('input[name="'+$titleSearch+'"]').val();
                var $colResult = $line.find('.' + $titleSearch);
                    $line.addClass('bg-col-search');
                    let $indexSearch = $colResult.text().toLowerCase().indexOf($keySearch.toLowerCase());
                    if($indexSearch > -1){
                        $keySearch = $colResult.text().substring($indexSearch, $indexSearch + $keySearch.length)
                        let $resultSearch = $colResult.text().replace($keySearch,'<span class="bg-result-search">'+$keySearch+'</span>');
                        $colResult.html($resultSearch);
                    }
                $line.scrollintoview({
                    behavior: 'smooth',
                    block: "center",
                    inline: "center"
                });
            }
        }
        //set value for input text search
        setValueInSelectText();
    })

    //change value limit record
    $("#count").on('change',function (){
        $('#form_search').submit();
    });

    //change value select type search
    $('[name=select_title]').on('change',setValueInSelectText);

    // submitチェック
    $('button[value="filter"]').click(function(){
        if($('[name=select_title]').val()){
            $(':hidden[name="button_type"]').val('filter');
            $('#form_search').submit();
        } else {
            alert('検索項目を選んでください。');
        }
    });

    $('button[value="search"]').click(function(){
        if($('[name=select_title]').val()){
            $(':hidden[name="button_type"]').val('search');
            $('#form_search').submit();
        } else {
            alert('検索項目を選んでください。');
        }
    });
    if ($(".alert").length){
        if ($(".alert-success").length){
            $(".close").html("");
            Swal.fire({
                icon: 'success',
                title: $(".alert").text(),
                timer: 3000
            })
        }
        if ($(".alert-danger").length){
            $(".close").html("");
            Swal.fire({
                icon: 'error',
                title: $(".alert").text(),
                timer: 3000
            })
        }
    }

    function setValueInSelectText(){
        $('#select_text').attr('name', $('[name=select_title]').val());
    }
})
