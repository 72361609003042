let $ = jQuery;
function openHeaderNav(e) {
    e.preventDefault();
    $('.header__nav').toggleClass('show-mobile');
    $('body').addClass('overflow-hidden')
}
function closeHeaderNav(e) {
    e.preventDefault();
    $('.header__nav').removeClass('show-mobile');
    $('body').removeClass('overflow-hidden')
}
export function headerEvents(){
    $(document).on('click', '.open-header-nav', openHeaderNav);
    $(document).on('click', '.close-header-nav, .header__nav.show-mobile', closeHeaderNav);
    $(document).on('click', '.header__nav > ul', (e) => e.stopPropagation());
}
