let $ = jQuery;

export function tableHeaderFixed(){
    if($(".table-scrollable").length){
        //fix height
        let heightExcessive = $('.header').outerHeight() + $('.list-page__head').outerHeight() + $('.list-page__bottom').outerHeight();
        $(".table-scrollable").css('height', 'calc(100vh - ' + heightExcessive + 'px)');

        // $(".table-scrollable").freezeTable({
        //     'scrollable': true,
        //     'freezeColumn': false,
        // });
    }
}
