function CompareDate($start, $end){
    if ($start.val() && $end.val()){
        let startVal = $start.val().split('-'),
            endVal = $end.val().split('-');
        if (startVal > endVal) {
            return false
        }else {
            return true
        }
    }else {
        return true
    }
}

let formatDate = 'Y/m/d';
$.datetimepicker.setLocale('ja');
export function datePicker(elmDate) {
    $(elmDate).datetimepicker({
        format: formatDate,
        timepicker:false,
        scrollInput: false
    });
}
export function dateRangePicker(that){
    let start = $(that).find('.date-start');
    let end = $(that).find('.date-end');
    start.datetimepicker({
        format: formatDate,
        onShow: function(){
            this.setOptions({
                maxDate: end.val() ? end.val() : false
            })
        },
        timepicker:false,
        scrollInput: false
    });
    end.datetimepicker({
        format: formatDate,
        onShow: function(){
            this.setOptions({
                minDate: start.val() ? start.val() : false
            })
        },
        timepicker:false,
        scrollInput: false
    });
}
